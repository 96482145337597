import { icon1 } from './icon1'
import { icon2 } from './icon2'
import { icon3 } from './icon3'
import { icon4 } from './icon4'
import { defaultIcon } from './default'

export const getIcon = (color, type) => {
  switch (type) {
    case 1: return icon1(color)
    case 2: return icon2(color)
    case 3: return icon3(color)
    case 4: return icon4(color)
    case 5: return defaultIcon(color)
    default: return defaultIcon(color)
  }
}
