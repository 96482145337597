/* eslint-disable eqeqeq */
import io from 'socket.io-client'
import { blockUrl } from './utils/blockedUrl'
import {
  LAUNCHER_URL, WIDGET_URL, SOCKET_URL,
  SOCKET_URL_V1, SOCKET_URL_V2, SOCKET_URL_V3,
  SOCKET_URL_V5, SOCKET_URL_V6, POWEREDBY_URL,
  MATERIAL_ICON_URL, API_BASE_URL, API_BASE_URL_BETA
} from './config/config'
import { prodV1, prodV2, prodV3, prodV5, prodV6 } from './bots'
import { storageFactory } from './storage/storage'
import { landingPage } from './landing'
import {
  CONTAINED,
  BOT_CENTER,
  BOT_LEFT,
  LAUNCHER_CLOSE,
  LAUNCHER_BUTTON_CLOSE,
  BOT_MESSAGE,
  EB_BOT_FAIL,
  BOT_BUTTON,
  BOT_MAIN,
  EMAIL_CAPTURED,
  PHONE_CAPTURED,
  BOT_STARTED,
  CONTAINED_THEME,
  CONTAINER_LESS_THEME
} from './constants'
import { LandingLauncher } from './landing-page'
import { setUpHeader } from './setup'
import { fetchUrl } from './utils/fetch'
import { getIsOnline } from './utils/isOnline'
import {
  createElement,
  getElementById,
  createStyleLink,
  createIframe,
  addClass,
  setAttribute
} from './utils/dom'
import { ProcessVariables } from './utils/variables'
import {
  Choices, hexToRgbA, isMobile, iframeID,
  stringifyJson, getTime, isEmpty, removeWhiteSpace, detectMob
} from './utils/utils'
import {
  checkEventEnabled,
  sendFbStarted,
  sendFbEmail,
  sendFbPhone,
  sendFbDismiss,
  sendFbCompleted
} from './utils/facebookPixel'
import { botLaunchButton, showBotTrigger, shouldPop } from './utils/cta'
import {
  ga, gAds, sendGaStarted, sendGaEmail,
  sendGaPhone, sendGaDismiss, sendGaCompleted, gaAdsSend
} from './utils/ga'
import { isGtag, sendGa4Started, sendGa4Completed, sendGa4Email, sendGa4Phone, sendGa4Dismiss } from './utils/ga4'

let poweredByText = 'By EnquiryBot'
let poweredByUrl = POWEREDBY_URL
var adKeyValue = ''
var ga4Code = ''
let POST_DATA_SENT = {}
let PREVIOUS_BOT = ''

const botURL = WIDGET_URL + 'bots'
const styleLinkUrl = LAUNCHER_URL + 'bot.css'

const storage = storageFactory()

const getBotAdEvents = botId => JSON.parse(storage.getItem(storage.getBotAdEventsKey(botId))) || []

const getBotGaEvents = botId => JSON.parse(storage.getItem(storage.getBotEventsKey(botId))) || []

const getBotGtagEvents = botId => JSON.parse(storage.getItem(storage.getGtagEventsKey(botId))) || []

const getContainer = (theme) => {
  if (isEmpty(theme)) return CONTAINER_LESS_THEME
  const style = theme.style
  if (theme.placement === 3) return CONTAINER_LESS_THEME
  if (style === 'Container Less') return CONTAINER_LESS_THEME
  if (style === 'Contained') return CONTAINED_THEME
}

let isOnline = true

window.addEventListener('online', () => {
  getIsOnline().then((online) => { isOnline = online }).catch(() => { isOnline = true })
})

window.addEventListener('offline', () => {
  getIsOnline().then((online) => { isOnline = online }).catch(() => { isOnline = true })
})

const Launcher = (script) => {
  const botId = removeWhiteSpace(script.dataset.botId)
  const referrerUrl = document.referrer
  if (script.dataset.landing) {
    // landing page launcher which is called when variable landing is set as true,
    // it is set only when it is embeded in the external site through the script,
    // provided in EB client landing page share tab.
    LandingLauncher(script)
    return
  }
  if (script.dataset.landingPage) {
    // This is invoked from the landing.html script to load the landing page
    let botData = script.dataset.bot
    if (!botData) {
      botData = { botId }
    }
    landingPage(botData, io, storage, script)
    return
  }
  const launchButton = createElement('div')
  const botWidget = createElement('div')
  botWidget.id = 'botwidget'
  addClass(botWidget, 'bot-eb-widget')
  addClass(botWidget, 'bot-hide')
  var iframe = createIframe(iframeID(botId), 'bot-iframe')
  setAttribute(iframe, 'data-position', 'center')
  addClass(iframe, BOT_LEFT)
  iframe.title = 'bot-iframe'
  iframe.style.height = '100%'
  iframe.style.width = '100%'
  iframe.style.borderWidth = '0px'
  const styleLink = createStyleLink(styleLinkUrl)
  const materialLink = createStyleLink(MATERIAL_ICON_URL)
  const containedHeader = document.createElement('div')
  const containedAvatar = document.createElement('div')
  const containedTitle = document.createElement('div')
  const containedclose = document.createElement('i')
  const poweredMain = document.createElement('div')
  const powered = document.createElement('a')
  const poweredMobile = document.createElement('div')
  const closeButton = document.createElement('i')

  const sessionId = storage.getSessionId(botId)
  let socket

  const triggerPostMessage = (data) => {
    getElementById(iframeID(botId)).contentWindow.postMessage(stringifyJson(data), WIDGET_URL)
  }

  const entryPoint = `?entry=${encodeURIComponent(document.location.href)}`
  const transportOptions = {
    transportOptions: {
      polling: {
        extraHeaders: {
          bot_id: botId,
          entry_point: document.location.href,
          session_id: sessionId,
          button_id: null,
          launcher: true
        }
      }
    }
  }

  let URL = SOCKET_URL
  let BASE_API = API_BASE_URL
  if (prodV1.includes(botId)) {
    URL = SOCKET_URL_V1
  } else if (prodV2.includes(botId)) {
    URL = SOCKET_URL_V2
  } else if (prodV3.includes(botId)) {
    URL = SOCKET_URL_V3
    BASE_API = API_BASE_URL_BETA
  } else if (prodV5.includes(botId)) {
    URL = SOCKET_URL_V5
    BASE_API = API_BASE_URL_BETA
  } else if (prodV6.includes(botId)) {
    URL = SOCKET_URL_V6
  }

  if (botId.length > 5) {
    socket = io(URL, transportOptions)
  }

  socket.on('disconnect', function () {
    socket.disconnect()
  })

  socket.on('connect_error', function (data) {
    socket.disconnect()
    // call api here if it is failing to-do
    fetchUrl(`${BASE_API}/users/bot_data/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        bot_id: botId,
        entry_point: document.location.href,
        button_id: '',
        location: '',
        referrer_url: document.referrer,
        headers: navigator.userAgent,
        time: getTime(),
        start: false
      })
    })
      .then(resp => {
        if (resp.data) {
          const { bot_data: botData } = resp.data
          botInitialize(botData)
        }
        if (resp.error) {
          console.log(resp.error)
        }
      })
  })

  const onStarted = () => {
    if (ga() && getBotGaEvents(botId).includes(Choices.BotEventChoice.ENQUIRY_STARTED.toString())) {
      sendGaStarted()
    }
    if (gAds() && getBotAdEvents(botId).includes(Choices.BotEventChoice.ENQUIRY_STARTED.toString())) {
      gaAdsSend(adKeyValue)
    }
    if (isGtag() && getBotGtagEvents(botId).includes(Choices.BotEventChoice.ENQUIRY_STARTED.toString())) {
      sendGa4Started(ga4Code)
    }
    if (checkEventEnabled(Choices.BotEventChoice.ENQUIRY_STARTED.toString(), botId, storage)) {
      sendFbStarted()
    }
  }

  const onCompleted = () => {
    if (ga() && getBotGaEvents(botId).includes(Choices.BotEventChoice.ENQUIRY_COMPLETED.toString())) {
      sendGaCompleted()
    }
    if (gAds() && getBotAdEvents(botId).includes(Choices.BotEventChoice.ENQUIRY_COMPLETED.toString())) {
      gaAdsSend(adKeyValue)
    }
    if (isGtag() && getBotGtagEvents(botId).includes(Choices.BotEventChoice.ENQUIRY_COMPLETED.toString())) {
      sendGa4Completed(ga4Code)
    }
    if (checkEventEnabled(Choices.BotEventChoice.ENQUIRY_COMPLETED.toString(), botId, storage)) {
      sendFbCompleted()
    }
    setTimeout(() => {
      if (JSON.parse(storage.getItem('botOpen')) && launchButton.classList.contains('bot-active')) {
        launchButton.click()
      }
      if (JSON.parse(storage.getItem('botCenterOpen')) && botWidget.classList.contains('bot-show')) {
        closeButton.click()
      }
    }, 1000)
  }

  const onEmailCaptured = () => {
    if (ga() && getBotGaEvents(botId).includes(Choices.BotEventChoice.EMAIL_CAPTURED.toString())) {
      sendGaEmail()
    }
    if (isGtag() && getBotGtagEvents(botId).includes(Choices.BotEventChoice.EMAIL_CAPTURED.toString())) {
      sendGa4Email(ga4Code)
    }
    if (gAds() && getBotAdEvents(botId).includes(Choices.BotEventChoice.EMAIL_CAPTURED.toString())) {
      gaAdsSend(adKeyValue)
    }
    if (checkEventEnabled(Choices.BotEventChoice.EMAIL_CAPTURED.toString(), botId, storage)) {
      sendFbEmail()
    }
  }

  const onPhoneCaptured = () => {
    if (ga() &&
      getBotGaEvents(botId).includes(Choices.BotEventChoice.PHONE_NUMBER_CAPTURED.toString())) {
      sendGaPhone()
    }
    if (gAds() && getBotAdEvents(botId).includes(Choices.BotEventChoice.PHONE_NUMBER_CAPTURED.toString())) {
      gaAdsSend(adKeyValue)
    }
    if (isGtag() && getBotGtagEvents(botId).includes(Choices.BotEventChoice.PHONE_NUMBER_CAPTURED.toString())) {
      sendGa4Phone(ga4Code)
    }
    if (checkEventEnabled(Choices.BotEventChoice.PHONE_NUMBER_CAPTURED.toString(), botId, storage)) {
      sendFbPhone()
    }
  }

  // open center bot function through the custom buttons
  const openCenterBot = (theme) => {
    const containerHeader = document.getElementById('contained-header')
    if (containerHeader) {
      containerHeader.parentNode.removeChild(containerHeader)
    }
    botWidget.classList.remove('bot-hide')
    closeButton.classList.remove('bot-hide')
    if (launchButton.classList.contains('bot-active')) {
      botWidget.classList.toggle('bot-show')
      const logo = document.getElementById('bot-logo')
      const close = document.getElementById('bot-close')
      const isActive = launchButton.classList.contains('bot-active')
      if (isActive) {
        poweredMain.classList.remove('bot-active')
        logo.classList.remove('bot-cover')
        close.classList.add('bot-cover')
        launchButton.classList.remove('bot-active')
      }
      storage.setItem('botOpen', false)
    }
    storage.setItem('botCenterOpen', true)
    if (!botWidget.classList.contains('bot-show')) {
      const transparancyColor = hexToRgbA(theme.overlay_color, theme.full_screen_transparency)
      botWidget.style.background = transparancyColor
      botWidget.classList.toggle('bot-show')
    }
  }

  const botInitialize = (data, that) => {
    let currentSessionId = 'api'
    if (that) {
      currentSessionId = that.id
    }
    const allData = {
      ...data,
      variables: ProcessVariables(data.variables)
    }
    let BUTTON_BOT = null
    let ACTIVE_BUTTON = null
    const themeData = data.bot_data.theme
    const botData = data.bot_data
    if (themeData.custom_branding) {
      poweredByText = `${themeData.powered_by}`
      poweredByUrl = themeData.website_url
    }
    const currentUrl = document.location.href
    // listen to the event session, and emit the socket event create_ref_url for that particular socket
    socket.on('session', function (data) {
      socket.emit('create_ref_url', {
        session_id: data.session_id,
        referrer_url: document.referrer
      })
    })

    let postData = {}
    if (blockUrl(botData, currentUrl)) {
      if (!document.getElementById(iframeID(botId))) {
        if (!iframe.src) {
          iframe.src = `${botURL}/${botId}/${currentSessionId}/${getContainer(themeData)}${entryPoint}`
        }
        // setup the header attributes
        setUpHeader(allData, botId, adKeyValue, storage)
        const hasAds = botData.has_ads
        if (hasAds) {
          adKeyValue = `${botData.ad_key}/${botData.ad_val}`
        }
        if (botData.has_ga4) {
          ga4Code = botData.ga4_code
        }
        const placement = Choices.BotPlacement[themeData.placement]
        if (themeData.style === CONTAINED) {
          if (placement !== BOT_CENTER) {
            botWidget.style.removeProperty('background')
            addClass(botWidget, 'contained')
            if (!themeData.is_rounded) {
              addClass(botWidget, 'edged')
              addClass(containedHeader, 'edged')
            }
            addClass(botWidget, placement)
            addClass(launchButton, 'contained')
            botWidget.appendChild(containedHeader)
          }
          if (themeData.bottom_spacing && !isMobile()) {
            botWidget.style.bottom = `calc(${themeData.bottom_spacing}px + 60px)`
          }
          if (themeData.side_spacing && !isMobile()) {
            if (themeData.placement == 1) {
              botWidget.style.left = `${themeData.side_spacing}px`
            }
            if (themeData.placement == 2) {
              botWidget.style.right = `${themeData.side_spacing}px`
            }
          }
        }
        if (themeData.style !== CONTAINED && themeData.style !== 'Contained') {
          if (themeData.side_spacing && !isMobile()) {
            if (themeData.placement == 1) {
              botWidget.style.paddingLeft = `${themeData.side_spacing}px`
              botWidget.style.width = `calc(${themeData.side_spacing}px + 400px)`
            } else if (themeData.placement == 2) {
              botWidget.style.paddingRight = `${themeData.side_spacing}px`
              botWidget.style.width = `calc(${themeData.side_spacing}px + 400px)`
            }
          }
        }

        // poweredBy for contained theme design
        const containedPowered = () => {
          if (!botData.hide_branding) {
            const poweredContainedMain = createElement('div')
            addClass(poweredContainedMain, 'containedpowered')
            if (themeData.style === 'Contained') {
              addClass(poweredContainedMain, 'contained')
            }
            const poweredContained = createElement('a')
            addClass(poweredContained, 'power')
            setAttribute(poweredContained, 'href', poweredByUrl)
            setAttribute(poweredContained, 'target', '_blank')
            setAttribute(poweredContained, 'rel', 'noopener noreferrer')
            poweredContained.innerText = poweredByText
            poweredContainedMain.appendChild(poweredContained)
            botWidget.insertAdjacentElement('beforeend', poweredContainedMain)
          }
        }

        // if postmessage with data is not sent to bot
        // re-ignite the postmessage to widget iframe
        window.addEventListener('message', e => {
          if (e.data) {
            if (e.data.bot_status === EB_BOT_FAIL) {
              getElementById(iframeID(botId)).contentWindow.postMessage(stringifyJson(POST_DATA_SENT), '*')
            }
            if (e.data.action === LAUNCHER_CLOSE) {
              if (e.data.data.exit) {
                onCompleted()
              }
            }
            if (e.data.action === LAUNCHER_BUTTON_CLOSE) {
              if (e.data.data.exit) {
                onCompleted()
              }
            }
            if (e.data.action === EMAIL_CAPTURED) {
              onEmailCaptured()
            }
            if (e.data.action === PHONE_CAPTURED) {
              onPhoneCaptured()
            }
            if (e.data.action === BOT_STARTED) {
              onPhoneCaptured()
            }
          }
        })

        // poweredBy enquiry bot container Theme
        const poweredContainer = (element, placement) => {
          if (!botData.hide_branding) {
            poweredMain.classList.add('powered')
            if (themeData.style === 'Contained') {
              poweredMain.classList.add('contained')
            }
            powered.href = poweredByUrl
            if (placement === 'bot-center') {
              poweredMain.classList.add('bot-right')
              powered.classList.add('bot-right')
              poweredMobile.classList.add('bot-right')
            } else {
              poweredMain.classList.add(placement)
              powered.classList.add(placement)
              poweredMobile.classList.add(placement)
            }
            powered.classList.add('power')
            poweredMobile.classList.add('power')
            powered.target = '_blank'
            powered.rel = 'noopener noreferrer'
            powered.innerText = poweredByText
            poweredMobile.innerText = poweredByText
            // not displaying the hyperlink for mobile version
            if (window.innerWidth <= 427) {
              poweredMain.appendChild(poweredMobile)
            } else {
              poweredMain.appendChild(powered)
            }
            element.insertAdjacentElement('beforeend', poweredMain)
          }
        }

        // launch button on hover functionalities
        launchButton.onmouseenter = () => {
          const isActive = launchButton.classList.contains('bot-active')
          const tooltip = getElementById('bot-tooltip')
          if (!isOnline && !isActive) {
            tooltip.style.opacity = 1
          } else {
            tooltip.style.opacity = 0
          }
          shouldPop(themeData, launchButton)
          if (themeData.start_on_hover) {
            if (!document.getElementById(iframeID(botId))) {
              setTimeout(() => {
                if (!launchButton.classList.contains('bot-active')) {
                  launchButton.click()
                }
              }, 700)
            }
          }
        }
        // contained on close functionalities
        containedclose.onclick = (event) => {
          launchButton.click()
          sendEvents(event)
        }

        const sendEvents = (event) => {
          const botEvents = getBotGaEvents(botId)
          const botAdEvents = getBotAdEvents(botId)
          const botGtagEvents = getBotGtagEvents(botId)
          if (window.ga) {
            if (event.x !== 0 && event.y !== 0) {
              if (botData.has_ga) {
                if (botEvents.includes(Choices.BotEventChoice.DISMISSED.toString())) {
                  sendGaDismiss('Enquiry Dismissed', referrerUrl)
                }
              }
            }
          }
          if (window.gtag) {
            if (event.x !== 0 && event.y !== 0) {
              if (botData.has_ads) {
                if (botAdEvents.includes(Choices.BotEventChoice.DISMISSED.toString())) {
                  gaAdsSend(adKeyValue, 'Enquiry Dismissed')
                }
              }
            }
          }
          if (event.x !== 0 && event.y !== 0 && botData.has_fb) {
            sendFbDismiss(botId)
          }
          if (event.x !== 0 && event.y !== 0) {
            if (botData.has_ga4) {
              if (botGtagEvents.includes(Choices.BotEventChoice.DISMISSED.toString())) {
                sendGa4Dismiss('Enquiry Dismissed', botData.ga4_code)
              }
            }
          }
        }
        // bot center close Button
        closeButton.onclick = (event) => {
          BUTTON_BOT = null
          ACTIVE_BUTTON = null
          storage.setItem('botCenterOpen', false)
          botWidget.classList.toggle('bot-show')
          setTimeout(() => {
            botWidget.style.removeProperty('background')
          }, 100)
          sendEvents(event)
          if (!botWidget.classList.contains('bot-show')) {
            setTimeout(() => {
              botWidget.classList.add('bot-hide')
              closeButton.classList.add('bot-hide')
              if (
                themeData.style !== CONTAINED &&
                themeData.style !== 'Contained'
              ) {
                if (themeData.side_spacing && !isMobile()) {
                  if (themeData.placement == 1) {
                    botWidget.style.paddingLeft = `${themeData.side_spacing}px`
                    botWidget.style.width = `calc(${themeData.side_spacing}px + 400px)`
                  } else if (themeData.placement == 2) {
                    botWidget.style.paddingRight = `${themeData.side_spacing}px`
                    botWidget.style.width = `calc(${themeData.side_spacing}px + 400px)`
                  }
                }
              } else {
                if (themeData.bottom_spacing && !isMobile()) {
                  botWidget.style.bottom = `calc(${themeData.bottom_spacing}px + 60px)`
                }
                if (themeData.side_spacing && !isMobile()) {
                  if (themeData.placement == 1) {
                    botWidget.style.left = `${themeData.side_spacing}px`
                  }
                  if (themeData.placement == 2) {
                    botWidget.style.right = `${themeData.side_spacing}px`
                  }
                }
              }
            }, 200)
          }
        }

        const widgetScreenStyle = (themeData) => {
          const gradientStart = themeData.desktop_side_screen_gradient_start_colour
          const gradientEnd = themeData.desktop_side_screen_gradient_end_colour
          const gradientTransparency = themeData.desktop_side_screen_transparency
          const mobileBg = themeData.mobile_background_colour
          const mobileBgTransparency = themeData.mobile_full_screen_transparency
          if (themeData.style !== 'Contained') {
            if (gradientStart && gradientEnd && mobileBg) {
              let gradientAngle = themeData.side_screen_gradient_angle
              // placement is left reverse the gradient
              if (themeData.placement == 1) {
                gradientAngle = `-${gradientAngle}`
              }
              if (isMobile()) {
                botWidget.style.background = hexToRgbA(mobileBg, mobileBgTransparency)
              } else {
                botWidget.style.backgroundImage = `linear-gradient(${gradientAngle}deg, ${hexToRgbA(gradientStart, gradientTransparency)} 1%, ${hexToRgbA(gradientEnd, 99)} 100%)`
              }
            }
          }
        }

        const launchButtonClick = (event) => {
          BUTTON_BOT = null
          ACTIVE_BUTTON = null
          botWidget.classList.remove('bot-hide')
          botWidget.classList.toggle('bot-show')
          launchButton.classList.toggle('bot-active')
          const isBotActive = launchButton.classList.contains('bot-active')

          // appending header for container because it was removed when center bot was opened
          if (themeData.style === 'Contained') {
            if (!document.getElementById('contained-header')) {
              botWidget.insertBefore(containedHeader, botWidget.firstChild)
            }
          }
          widgetScreenStyle(themeData)
          iframe.setAttribute('name', 'bot-iframe')
          if (isBotActive) {
            storage.setItem('botOpen', true)
          } else {
            storage.setItem('botOpen', false)
            sendEvents(event)
          }
          iframe.src = `${botURL}/${botId}/${currentSessionId}/${getContainer(themeData)}${entryPoint}`
          postData = {
            action: BOT_MESSAGE,
            data: {
              name: iframe.name,
              entry_point: currentUrl,
              theme: themeData,
              referrerUrl,
              active: isBotActive
            }
          }
          POST_DATA_SENT = postData
          iframe.onload = () => {
            if (isBotActive) {
              triggerPostMessage({ ready: true })
            }
            setTimeout(() => {
              if (isBotActive) {
                triggerPostMessage(postData)
              }
            }, 100)
          }
          if (!document.getElementById(iframeID(botId))) {
            botWidget.append(iframe)
            if (themeData.style === CONTAINED) {
              if (window.innerWidth >= 426) {
                if (themeData.eb_branding || themeData.custom_branding) {
                  containedPowered()
                }
              }
            }
          } else {
            const iframeElement = document.getElementById(iframeID(botId))
            if (PREVIOUS_BOT === BOT_BUTTON) {
              iframeElement.remove()
              botWidget.append(iframe)
            }
          }
          PREVIOUS_BOT = BOT_MAIN
          if (botWidget.classList.contains(BOT_CENTER)) {
            botWidget.classList.remove(BOT_CENTER)
            botWidget.classList.add(placement)
          }
          if (showBotTrigger(botData)) {
            const logo = document.getElementById('bot-logo')
            const close = document.getElementById('bot-close')
            if (isBotActive) {
              setTimeout(() => {
                poweredMain.classList.add('bot-active')
              }, 0)
              close.classList.remove('bot-cover')
              logo.classList.add('bot-cover')
            } else {
              poweredMain.classList.remove('bot-active')
              close.classList.add('bot-cover')
              logo.classList.remove('bot-cover')
            }
            if (!isBotActive) {
              setTimeout(() => {
                botWidget.classList.add('bot-hide')
              }, 100)
            }
          }
        }
        // launch button on click functionalities
        launchButton.onclick = (event) => {
          if (!isOnline && !launchButton.classList.contains('bot-active')) {
            return
          }
          launchButtonClick(event)
        }
        // function to trigger bot through external button
        const triggerBot = (current) => {
          let currentBotId
          let buttonId
          if (current.currentTarget.dataset) {
            currentBotId = current.currentTarget.dataset.botId
            buttonId = current.currentTarget.dataset.buttonId
          } else {
            currentBotId = current.srcElement.dataset.botId
            buttonId = current.srcElement.dataset.buttonId
          }
          if (!currentBotId) {
            currentBotId = botId
          }
          ACTIVE_BUTTON = buttonId
          BUTTON_BOT = currentBotId
          iframe.setAttribute('name', 'bot-iframe-center')
          postData = {
            action: BOT_MESSAGE,
            data: {
              name: iframe.name,
              entry_point: currentUrl,
              theme: themeData,
              referrerUrl,
              button_id: buttonId,
              active: launchButton.classList.contains('bot-active')
            }
          }
          POST_DATA_SENT = postData
          iframe.onload = () => {
            triggerPostMessage({ ready: true })
            setTimeout(() => {
              triggerPostMessage(postData)
              setTimeout(() => {
                iframe.onload = () => { }
              }, 1000)
            }, 100)
          }
          setTimeout(() => {
            openCenterBot(themeData)
          }, 0)
          let buttonUrl = `${botURL}/${currentBotId}/${currentSessionId}/${CONTAINER_LESS_THEME}`
          if (!document.getElementById(iframeID(botId))) {
            iframe.setAttribute('name', 'bot-iframe-center')
            if (buttonId) {
              buttonUrl = `${buttonUrl}/${buttonId}`
            }
            iframe.src = `${buttonUrl}${entryPoint}`
            botWidget.append(iframe)
          } else {
            const iframeElement = document.getElementById(iframeID(botId))
            iframe.setAttribute('name', 'bot-iframe-center')
            if (buttonId) {
              buttonUrl = `${buttonUrl}/${buttonId}`
            }
            const src = `${buttonUrl}${entryPoint}`
            if (iframeElement.src !== src || PREVIOUS_BOT === BOT_MAIN) {
              iframeElement.remove()
            }
            if (!document.getElementById(iframeID(botId))) {
              iframe.src = src
              const BOTWIDGET = document.getElementById('botwidget')
              BOTWIDGET.appendChild(iframe)
            }
          }
          if (iframe.contentWindow) {
            botWidget.classList.remove(placement)
            botWidget.classList.add('bot-center')
            if (
              themeData.style === CONTAINED ||
              themeData.style === 'Contained'
            ) {
              botWidget.style.removeProperty('bottom')
              botWidget.style.removeProperty('left')
              botWidget.style.removeProperty('right')
            } else {
              botWidget.style.removeProperty('padding-right')
              botWidget.style.removeProperty('padding-left')
              botWidget.style.removeProperty('width')
            }
          }
          PREVIOUS_BOT = BOT_BUTTON
        }

        const externalButtonCheck = () => {
          const customButton = document.getElementsByName('bot-trigger')
          for (let i = 0; i < customButton.length; i++) {
            customButton[i].addEventListener('click', triggerBot)
          }
        }
        // check for external button
        externalButtonCheck()
        // check for location change and if location changed then check for
        // external button
        let lastUrl = location.href
        new MutationObserver(() => {
          const url = location.href
          if (url !== lastUrl) {
            lastUrl = url
            onUrlChange()
          }
        }).observe(document, { subtree: true, childList: true })

        const onUrlChange = () => {
          setTimeout(() => {
            externalButtonCheck()
          }, 100)
        }
        // launch button placement
        botWidget.classList.add(placement)
        botWidget.appendChild(closeButton)
        if (placement === 'bot-center') {
          launchButton.classList.add('bot-right')
        } else {
          launchButton.classList.add(placement)
        }
        document.head.appendChild(materialLink)
        document.head.append(styleLink)
        if (!document.getElementById('eb-bot-trigger')) {
          // if bot is deactivated dont append the trigger button
          if (showBotTrigger(botData)) {
            botLaunchButton(launchButton, themeData)
            if (themeData.eb_branding || themeData.custom_branding) {
              poweredContainer(launchButton, placement)
            }
          }
          if (!document.getElementById('botwidget')) {
            document.body.appendChild(botWidget)
          }
        }

        styleLink.onload = () => {
          const isMobile = detectMob()
          setTimeout(() => {
            // bot-center close button
            closeButton.classList.add('bot-center-close')
            if (iframe.name === 'bot-iframe') {
              closeButton.classList.add('bot-hide')
            }
            closeButton.classList.add('material-icons')
            closeButton.innerHTML = 'highlight_off'
            // bot contained Header style
            containedHeader.classList.add('bot-header')
            containedHeader.setAttribute('id', 'contained-header')
            containedAvatar.classList.add('bot-avatar')
            containedTitle.classList.add('bot-title')
            containedTitle.innerHTML = botData.name
            containedclose.innerHTML = 'close'
            containedclose.classList.add('bot-contained-close')
            containedclose.classList.add('material-icons')
            containedHeader.appendChild(containedAvatar)
            containedHeader.appendChild(containedTitle)
            containedHeader.appendChild(containedclose)
            if (!document.getElementById('eb-bot-trigger')) {
              if (showBotTrigger(botData)) {
                // if bot is deactivated dont append the trigger button
                if (document.getElementById('botwidget')) {
                  document.body.insertBefore(launchButton, botWidget)
                }
              }
            }
            setTimeout(() => {
              if (showBotTrigger(botData)) {
                if (!isMobile) {
                  if (themeData.show_bot_on_desktop && themeData.automatically_open_the_bot_on_desktop) {
                    if (!document.getElementById(iframeID(botId))) {
                      if (JSON.parse(storage.getItem('botOpen')) === null) {
                        launchButton.click()
                      } else if (JSON.parse(storage.getItem('botOpen'))) {
                        launchButton.click()
                      }
                    }
                  }
                }
                if (isMobile) {
                  if (themeData.show_bot_on_mobile && themeData.automatically_open_the_bot_on_mobile) {
                    if (!document.getElementById(iframeID(botId))) {
                      if (JSON.parse(storage.getItem('botOpen')) === null) {
                        launchButton.click()
                      } else if (JSON.parse(storage.getItem('botOpen'))) {
                        launchButton.click()
                      }
                    }
                  }
                }
              }
            }, parseInt(isMobile ? themeData.automatically_open_the_bot_on_mobile_delay : themeData.automatically_open_the_bot_on_desktop_delay) * 1000)
            setTimeout(() => {
              if (showBotTrigger(botData)) {
                shouldPop(themeData, launchButton)
              }
            }, parseInt(parseInt(isMobile ? themeData.automatically_open_the_bot_on_mobile_delay : themeData.automatically_open_the_bot_on_desktop_delay) * 1000) / 3)
          }, 1000)
        }
      }
    }
  }

  // socket initialize
  socket.on('initialize', function (data) {
    const that = this
    botInitialize(data, that)
  })

  socket.on('started', function () {
    onStarted()
  })
}

Launcher(document.currentScript)
