import 'regenerator-runtime/runtime'

export const fetchUrl = async (url, params) => {
  return await fetch(url, params)
    .then(resp => resp.json())
    .then(data => {
      return { data }
    })
    .catch(err => {
      return { error: err }
    })
}
