export const createElement = (ele) => document.createElement(ele)

export const getElementsByTagName = (name) => document.getElementsByTagName(name)

export const getElementById = (id) => document.getElementById(id)

export const getElementsByName = (name) => document.getElementsByName(name)

export const addClass = (elem, className) => elem.classList.add(className)

export const removeClass = (elem, className) => elem.classList.remove(className)

export const setAttribute = (elem, attr, value) => elem.setAttribute(attr, value)

export const createStyleLink = (url) => {
  const styleLink = document.createElement('link')
  styleLink.rel = 'stylesheet'
  styleLink.type = 'text/css'
  styleLink.href = url
  return styleLink
}

export const createInlineStyle = (id, styleContent) => {
  const style = document.createElement('style')
  style.id = id
  style.rel = 'stylesheet'
  style.innerHTML = styleContent
  return style
}

export const createIframe = (id, name) => {
  const iframe = document.createElement('iframe')
  iframe.id = id
  iframe.name = name
  iframe.style.height = '100%'
  iframe.style.width = '100%'
  return iframe
}
