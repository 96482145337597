export const icon3 = (color) => {
  return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="375pt" height="374.999991pt" viewBox="0 0 375 374.999991" version="1.2">
  <defs>
  <clipPath id="clip1">
    <path d="M 27.609375 22.722656 L 350.859375 22.722656 L 350.859375 339 L 27.609375 339 Z M 27.609375 22.722656 "/>
  </clipPath>
  </defs>
  <g id="surface1">
  <rect x="0" y="0" width="375" height="374.999991" style="fill:transparent;fill-opacity:1;stroke:none;"/>
  <rect x="0" y="0" width="375" height="374.999991" style="fill:transparent;fill-opacity:1;stroke:none;"/>
  <g clip-path="url(#clip1)" clip-rule="nonzero">
  <path style=" stroke:none;fill-rule:nonzero;fill:${color};fill-opacity:1;" d="M 125.671875 -45.632812 C 114.847656 -45.632812 106.0625 -36.84375 106.0625 -26.019531 C 106.0625 -15.191406 114.847656 -6.40625 125.671875 -6.40625 L 380.636719 -6.40625 L 380.636719 170.105469 C 380.636719 180.933594 389.421875 189.71875 400.25 189.71875 C 411.074219 189.71875 419.859375 180.933594 419.859375 170.105469 L 419.859375 -6.40625 C 419.859375 -28.078125 402.308594 -45.632812 380.636719 -45.632812 Z M 66.835938 32.820312 C 45.261719 32.820312 27.800781 50.46875 27.800781 72.042969 L 27.648438 318.847656 C 27.628906 336.320312 48.773438 345.109375 61.128906 332.753906 L 106.0625 287.78125 L 302.1875 287.78125 C 323.757812 287.78125 341.410156 270.128906 341.410156 248.554688 L 341.410156 72.042969 C 341.410156 50.46875 323.757812 32.820312 302.1875 32.820312 Z M 66.835938 32.820312 "/>
  </g>
  </g>
  </svg>`
}
