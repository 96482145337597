export const storageFactory = () => {
  let inMemoryStorage = {}

  const getSessionKey = (botId) => {
    return `sessionId::${botId}`
  }

  const getBotEventsKey = (botId) => {
    return `botEvents::${botId}`
  }

  const getBotAdEventsKey = (botId) => {
    return `botAdEvents::${botId}`
  }
  const getGtagEventsKey = (botId) => {
    return `botGtagEvent::${botId}`
  }
  const getFbStorageKey = botId => {
    return `botFbEvents::${botId}`
  }

  const isStorageSupported = () => {
    try {
      const testKey = '__some_random_key'
      localStorage.setItem(testKey, testKey)
      localStorage.removeItem(testKey)
      return true
    } catch (e) {
      return false
    }
  }

  const getItem = (name) => {
    if (isStorageSupported()) {
      return localStorage.getItem(name)
    }
    if (inMemoryStorage[name]) {
      return inMemoryStorage[name]
    }
    return null
  }

  const setItem = (name, value) => {
    if (isStorageSupported()) {
      return localStorage.setItem(name, value)
    } else {
      inMemoryStorage[name] = value
    }
  }

  const removeItem = (name) => {
    if (isStorageSupported()) {
      localStorage.removeItem(name)
    } else {
      delete inMemoryStorage[name]
    }
  }

  const getSessionId = (name) => {
    const key = getSessionKey(name)
    return getItem(key)
  }

  const setSessionId = (name, value) => {
    const key = getSessionKey(name)
    setItem(key, value)
  }

  const removeSessionId = (name) => {
    const key = getSessionKey(name)
    removeItem(key)
  }

  const clear = () => {
    inMemoryStorage = {}
  }

  return {
    getItem,
    setItem,
    removeItem,
    getSessionId,
    setSessionId,
    removeSessionId,
    getSessionKey,
    getBotEventsKey,
    getBotAdEventsKey,
    getFbStorageKey,
    getGtagEventsKey,
    clear
  }
}
