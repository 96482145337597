export const fbInitialize = (pixelId) => {
  if (document.getElementById('FB-analytical')) { return }

  const analyticalScript = document.createElement('script')
  analyticalScript.id = 'FB-analytical'
  analyticalScript.async = true

  analyticalScript.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${pixelId}');
        fbq('track', 'PageView');`

  document.head.append(analyticalScript)
}

export const checkEventEnabled = (event, botId, storage) => {
  if (!botId || !window.fbq) { return false }
  const allowedEvents = storage.getItem(storage.getFbStorageKey(botId)) ? JSON.parse(storage.getItem(storage.getFbStorageKey(botId))) : []
  return allowedEvents && allowedEvents.includes ? allowedEvents.includes(event) : false
}

export const sendFbDismiss = botId => {
  window.fbq('trackCustom', 'DismissCaptured')
}

export const sendFbStarted = botId => {
  window.fbq('trackCustom', 'StartCaptured')
}

export const sendFbEmail = botId => {
  window.fbq('trackCustom', 'EmailCaptured')
}

export const sendFbPhone = botId => {
  window.fbq('trackCustom', 'PhoneNumberCaptured')
}

export const sendFbCompleted = botId => {
  window.fbq('trackCustom', 'CompletedCaptured')
}
