import { isEmpty } from './utils'

export const blockUrl = (data, currentUrl) => {
  if (window.location.href.includes('/preview/')) {
    return true
  }
  if (data.access_type === 0) {
    return !data.blocked_urls.includes(currentUrl)
  }
  let isSlugFound = false
  if (data.access_type === 1) {
    data.blocked_urls.forEach(url => {
      if (currentUrl.includes(url)) {
        isSlugFound = true
      }
    })
    if (isSlugFound) return false
    return true
  }
  if (data.access_type === 2) {
    let isAllowed = false
    if (currentUrl.includes('?')) {
      const newUrl = currentUrl.split('?')
      if (!isEmpty(newUrl)) {
        data.blocked_urls.forEach((url) => {
          if (url === newUrl[0]) {
            isAllowed = true
          }
        })
      }
    } else {
      data.blocked_urls.forEach((url) => {
        if (url === currentUrl) {
          isAllowed = true
        }
      })
    }
    return isAllowed
  }
  if (data.access_type === 3) {
    return true
  }
  return true
}
