export const icon2 = (color) => {
  return `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="375pt" height="374.999991pt" viewBox="0 0 375 374.999991" version="1.2">
<defs>
<clipPath id="clip1">
  <path d="M 19.769531 37 L 352.019531 37 L 352.019531 342 L 19.769531 342 Z M 19.769531 37 "/>
</clipPath>
</defs>
<g id="surface1">
<rect x="0" y="0" width="375" height="374.999991" style="fill:transparent;fill-opacity:1;stroke:none;"/>
<rect x="0" y="0" width="375" height="374.999991" style="fill:transparent;fill-opacity:1;stroke:none;"/>
<g clip-path="url(#clip1)" clip-rule="nonzero">
<path style=" stroke:none;fill-rule:nonzero;fill:${color};fill-opacity:1;" d="M 52.992188 37.011719 C 34.71875 37.011719 19.769531 51.96875 19.769531 70.25 L 19.769531 249.613281 C 19.769531 257.007812 28.714844 260.722656 33.949219 255.488281 L 69.605469 219.816406 L 219.117188 219.816406 C 237.394531 219.816406 252.34375 204.859375 252.34375 186.578125 L 252.34375 70.25 C 252.34375 51.96875 237.394531 37.011719 219.117188 37.011719 Z M 285.570312 120.105469 L 285.570312 186.578125 C 285.570312 223.289062 255.816406 253.054688 219.117188 253.054688 L 119.445312 253.054688 L 119.445312 269.671875 C 119.445312 287.953125 134.394531 302.910156 152.667969 302.910156 L 302.179688 302.910156 L 337.839844 338.582031 C 343.074219 343.816406 352.019531 340.101562 352.019531 332.707031 L 352.019531 153.34375 C 352.019531 135.0625 337.066406 120.105469 318.792969 120.105469 Z M 285.570312 120.105469 "/>
</g>
</g>
</svg>`
}
