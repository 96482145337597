import {
  sanatizeObjectData,
  lightenDarkenColor
} from './utils/utils'
import {
  getElementById,
  createInlineStyle
} from './utils/dom'
import { fbInitialize } from './utils/facebookPixel'
import { googleAnalyticsInitialize } from './utils/googleAnalytics'
import { gtagInitialize } from './utils/gtag'
import { googleAdsInitialize } from './utils/googleAds'
import { BOT_IMAGE_URL } from './config/config'

const drrorymcgoldrickBotId = '2331f1e9-a903-4cc7-87a3-795ee5f58257'

const clientBasedFontFamily = (botId) => {
  if (botId === drrorymcgoldrickBotId) {
    return 'gotham, sans-serif'
  }
  return 'proxima-nova, sans-serif'
}

export const setUpHeader = (data, botId, adKeyValue, storage) => {
  const themeData = data.bot_data.theme
  const botData = data.bot_data

  document.documentElement.style.setProperty('--primary-color', sanatizeObjectData(themeData.primary_color, '#3856de'))
  document.documentElement.style.setProperty('--primary-color-lite', lightenDarkenColor(sanatizeObjectData(themeData.primary_color, '#3856de'), 70))
  if (themeData.is_cta_bg_gradient) {
    document.documentElement.style.setProperty(
      '--pop-over-bg',
      sanatizeObjectData(themeData.cta_bg_gradient_left)
    )
    document.documentElement.style.setProperty(
      '--pop-over-bg-lite',
      lightenDarkenColor(
        sanatizeObjectData(themeData.cta_bg_gradient_right),
        70
      )
    )
  } else {
    document.documentElement.style.setProperty(
      '--pop-over-bg',
      sanatizeObjectData(themeData.cta_btn_bg_solid_color)
    )
    document.documentElement.style.setProperty(
      '--pop-over-bg-lite',
      lightenDarkenColor(
        sanatizeObjectData(themeData.cta_btn_bg_solid_color),
        70
      )
    )
  }
  document.documentElement.style.setProperty('--pop-over-text', sanatizeObjectData(themeData.popover_text_colour, '#ffffff'))
  document.documentElement.style.setProperty('--overlay-color', sanatizeObjectData(themeData.overlay_color, '#fecc02'))
  document.documentElement.style.setProperty('--overlay-close', sanatizeObjectData(themeData.overlay_close, '#ffffff'))
  document.documentElement.style.setProperty('--contained-background', sanatizeObjectData(themeData.contained_background, '#e6e6e6'))
  document.documentElement.style.setProperty('--contained-font-family', clientBasedFontFamily(botId))
  if (themeData.secondary_color) {
    document.documentElement.style.setProperty('--secondary-lite', lightenDarkenColor(themeData.secondary_color, 50))
  }
  const avatarStyle = createInlineStyle('bot-avatar',
    `.bot-avatar {
    background-image: url(${BOT_IMAGE_URL}) !important;
    }`
  )
  const botAvatar = getElementById('bot-avatar')
  if (!botAvatar) {
    document.head.append(avatarStyle)
  }
  if (botData.is_uploaded) {
    avatarStyle.innerHTML = `.bot-avatar {
      background-image: url(${botData.avatar}) !important;
    }`
  }
  if (themeData && themeData.selected_avatar) {
    avatarStyle.innerHTML = `.bot-avatar {
        background-image: url(${themeData.selected_avatar}) !important;
      }`
  }
  if (botAvatar) {
    botAvatar.innerHTML = avatarStyle.innerHTML
  }

  const hasGa4 = botData.has_ga4
  const hasGa = botData.has_ga
  const hasAds = botData.has_ads
  if (hasGa) {
    storage.setItem(storage.getBotEventsKey(botId), JSON.stringify(botData.events))
    googleAnalyticsInitialize(botData.ga_code)
  }
  if (hasGa4) {
    storage.setItem(storage.getGtagEventsKey(botId), JSON.stringify(botData.events_ga4))
    gtagInitialize(botData.ga4_code)
  }
  if (hasAds) {
    storage.setItem(storage.getBotAdEventsKey(botId), JSON.stringify(botData.ad_events))
    adKeyValue = `${botData.ad_key}/${botData.ad_val}`
    googleAdsInitialize(botData.ad_key)
  }
  if (botData.has_fb) {
    storage.setItem(storage.getFbStorageKey(botId), JSON.stringify(botData.fb_events))
    fbInitialize(botData.fb_key)
  }
}
