// This is invoked for widget to be loaded in iframe for landing page bot
import {
  WIDGET_URL, SOCKET_URL, API_BASE_URL, API_BASE_URL_BETA,
  SOCKET_URL_V1, SOCKET_URL_V2, SOCKET_URL_V3,
  SOCKET_URL_V5, SOCKET_URL_V6
} from './config/config'
import { prodV1, prodV2, prodV3, prodV5, prodV6 } from './bots'
import { iframeID, stringifyJson, getTime } from './utils/utils'
import {
  BOT_MESSAGE,
  EB_BOT_FAIL,
  CONTAINER_LESS_THEME
} from './constants'
import {
  getElementById,
  createElement,
  addClass
} from './utils/dom'
import { fetchUrl } from './utils/fetch'

export const landingPage = (bot, io, storage, script) => {
  const botId = bot.botId
  const sessionId = storage.getSessionId(botId)
  const currentUrl = `${document.referrer}landing-page/BotType-${botId}`
  const botURL = WIDGET_URL + 'bots'
  let socket
  var iframe = createElement('iframe')
  iframe.id = iframeID(botId)
  iframe.name = 'bot-iframe-center'
  iframe.setAttribute('data-position', 'center')
  iframe.classList.add('bot-left')
  iframe.style.height = '100%'
  iframe.style.width = '100%'
  iframe.frameBorder = 0
  iframe.title = 'bot-iframe-center'

  const entryPoint = `?entry=${encodeURIComponent(currentUrl)}`
  const transportOptions = {
    transportOptions: {
      polling: {
        extraHeaders: {
          bot_id: botId,
          entry_point: currentUrl,
          session_id: sessionId,
          launcher: true
        }
      }
    }
  }

  let URL = SOCKET_URL
  let BASE_API = API_BASE_URL
  if (prodV1.includes(botId)) {
    URL = SOCKET_URL_V1
  } else if (prodV2.includes(botId)) {
    URL = SOCKET_URL_V2
  } else if (prodV3.includes(botId)) {
    URL = SOCKET_URL_V3
    BASE_API = API_BASE_URL_BETA
  } else if (prodV5.includes(botId)) {
    URL = SOCKET_URL_V5
  } else if (prodV6.includes(botId)) {
    URL = SOCKET_URL_V6
  }
  if (botId.length > 5) {
    socket = io(URL, transportOptions)
  }

  const triggerPostMessage = (data) => {
    getElementById(iframeID(botId)).contentWindow.postMessage(stringifyJson(data), '*')
  }

  socket.on('connect_error', function (data) {
    socket.disconnect()
    // call api here if it is failing to-do
    fetchUrl(`${BASE_API}/users/bot_data/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        bot_id: botId,
        entry_point: document.location.href,
        button_id: '',
        location: '',
        referrer_url: document.referrer,
        headers: navigator.userAgent,
        time: getTime(),
        start: false
      })
    })
      .then(resp => {
        if (resp.data) {
          const { bot_data: botData } = resp.data
          botInitialize(botData)
        }
        if (resp.error) {
          const error = createElement('div')
          addClass(error, 'bot-error-container')
          error.innerHTML = 'Something went wrong, Please try again !!'
          document.body.appendChild(error)
          console.log(resp.error)
        }
      })
  })

  const botInitialize = (data, that) => {
    let currentSessionId = 'api'
    if (that) {
      currentSessionId = that.id
    }
    let POST_DATA_SENT = {}
    window.addEventListener('message', e => {
      if (e.data) {
        if (e.data.bot_status === EB_BOT_FAIL) {
          getElementById(iframeID(botId)).contentWindow.postMessage(stringifyJson(POST_DATA_SENT), '*')
        }
      }
    })
    const { bot_data: botData } = data
    let theme = botData.theme

    if (!theme) {
      theme = botData.theme
    }
    let postData = {}
    postData = {
      action: BOT_MESSAGE,
      data: {
        name: iframe.name,
        entry_point: currentUrl,
        theme: theme,
        landingPage: true,
        active: true
      }
    }
    POST_DATA_SENT = postData
    if (!document.getElementById(iframeID(botId))) {
      iframe.src = `${botURL}/${botId}/${currentSessionId}/${CONTAINER_LESS_THEME}${entryPoint}`
      document.body.appendChild(iframe)
    }
    iframe.onload = () => {
      setTimeout(() => {
        triggerPostMessage(postData)
      }, 300)
    }
  }

  socket.on('initialize', function (data) {
    socket.on('session', function (data) {
      storage.setSessionId(botId, data.session_id)
    })
    const that = this
    botInitialize(data, that)
  })
}
