export const iframeID = (botId) => `iframe-bot-${botId}`

export const parseJson = (val) => JSON.parse(val)

export const stringifyJson = (val) => JSON.stringify(val)

export const merge = (a, b, prop) => {
  var reduced = a.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]))
  return reduced.concat(b)
}

export const formatMinutes = (min) => {
  if (min < 10) {
    return `0${min}`
  }
  return min
}

export const getTime = () => {
  const dateObj = new Date()
  return `${dateObj.getHours()}:${formatMinutes(dateObj.getMinutes())}`
}

export const detectMob = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ]
  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}

export const isMacSafari = () => {
  return (navigator.vendor !== null && navigator.vendor.includes('Apple Computer, Inc') && navigator.userAgent.indexOf('Safari') !== -1)
}

export const isIOS = () => {
  return ([
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document))
}

export const Choices = {
  BotPlacement: {
    1: 'bot-left',
    2: 'bot-right',
    3: 'bot-center'
  },
  BotEventChoice: {
    ENQUIRY_STARTED: 1,
    ENQUIRY_COMPLETED: 2,
    EMAIL_CAPTURED: 3,
    PHONE_NUMBER_CAPTURED: 4,
    DISMISSED: 5,
    IGNORED: 6
  }
}

export const sanatizeObjectData = (data, value) => {
  if (data) {
    return data
  }
  return value
}

export const isMobile = () => {
  return window.innerWidth <= 500
}

export const hexToRgbA = (hex, transparancy = 0) => {
  const isHex = new RegExp(/^#[0-9A-F]{6}$/i)
  var c
  if (isHex.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + (100 - transparancy) + '%)'
  }
  throw new Error('Bad Hex')
}

export const lightenDarkenColor = (col, amt) => {
  var usePound = false
  if (col[0] === '#') {
    col = col.slice(1)
    usePound = true
  }
  var num = parseInt(col, 16)
  var r = (num >> 16) + amt
  if (r > 255) r = 255
  else if (r < 0) r = 0
  var b = ((num >> 8) & 0x00FF) + amt
  if (b > 255) b = 255
  else if (b < 0) b = 0
  var g = (num & 0x0000FF) + amt
  if (g > 255) g = 255
  else if (g < 0) g = 0
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

export const isEmpty = (val) => {
  // Stolen From: https://stackoverflow.com/a/28953167
  /*
  test results
  --------------
  []        true, empty array
  {}        true, empty object
  null      true
  undefined true
  ""       true, empty string
  ''        true, empty string
  0         false, number
  true      false, boolean
  false     false, boolean
  Date      false
  function  false
  */
  if (val === undefined) {
    return true
  }
  if (typeof (val) === 'function' || typeof (val) === 'number' || typeof (val) === 'boolean' || Object.prototype.toString.call(val) === '[object Date]') {
    return false
  }
  if (val === null || val.length === 0) {
    return true
  }
  if (typeof (val) === 'object') {
    if (Object.keys(val).length === 0) {
      return true
    }
  }
  return false
}

export const getExpiryTime = () => {
  const someDate = new Date()
  const numberOfDaysToAdd = 1
  const result = new Date(someDate.setDate(someDate.getDate() + numberOfDaysToAdd))
  const unixTimeStamp = Math.floor(result.getTime() / 1000)
  return unixTimeStamp
}

export const getCurrentTime = () => {
  const someDate = new Date()
  const unixTimeStamp = Math.floor(someDate.getTime() / 1000)
  return unixTimeStamp
}

export const svgToBase64Url = (svg) => {
  var decoded = unescape(encodeURIComponent(svg))
  var base64 = window.btoa(decoded)
  var imgSource = `data:image/svg+xml;base64,${base64}`
  return imgSource
}

export const removeWhiteSpace = (string) => string.replace(/\s/g, '')
