export const ga = () => {
  return window.ga || null
}

export const gaSend = (data) => {
  return window.ga('send', {
    hitType: 'event',
    eventCategory: data,
    eventAction: data
  })
}

export const sendGaStarted = (referrer) => {
  return window.ga('E0.send', {
    hitType: 'event',
    eventCategory: 'EB Chats',
    eventAction: 'Enquiry Started'
  })
}

export const sendGaEmail = (referrer) => {
  return window.ga('E1.send', {
    hitType: 'event',
    eventCategory: 'EB Chats',
    eventAction: 'Email Captured'
  })
}

export const sendGaPhone = (referrer) => {
  return window.ga('E2.send', {
    hitType: 'event',
    eventCategory: 'EB Chats',
    eventAction: 'Phone Captured'
  })
}

export const sendGaDismiss = (referrer) => {
  return window.ga('E3.send', {
    hitType: 'event',
    eventCategory: 'EB Chats',
    eventAction: 'Enquiry Dismissed'
  })
}

export const sendGaCompleted = (referrer) => {
  return window.ga('E4.send', {
    hitType: 'event',
    eventCategory: 'EB Chats',
    eventAction: 'Enquiry Completed'
  })
}

export const gAds = () => {
  return window.gtag || null
}

export const gaAdsSend = (id, value) => {
  return window.gtag('event', 'conversion', { send_to: id })
}
