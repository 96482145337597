export const gtagInitialize = (ga4Code) => {
  if (document.getElementById('gtag-analytical-launcher')) { return }
  const analyticalScript = document.createElement('script')
  analyticalScript.id = 'gtag-analytical-launcher'
  analyticalScript.async = true
  analyticalScript.src = `https://www.googletagmanager.com/gtag/js?id=${ga4Code}`
  const gtagScript = document.createElement('script')
  gtagScript.id = 'gtag-script'
  gtagScript.innerHTML = `
  window.dataLayer = window.dataLayer || []
  function gtag() {
    dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', '${ga4Code}')
  window.gtagalias = gtag
  `
  document.head.append(analyticalScript)
  document.head.append(gtagScript)
}
