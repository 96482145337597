export const EB_BOT_FAIL = 'FAIL'
export const CONTAINED = 'Contained'
export const BOT_CENTER = 'bot-center'
export const BOT_LEFT = 'bot_left'
export const BOT_RIGHT = 'bot_right'
export const LAUNCHER_CLOSE = 'launcher_close'
export const LAUNCHER_BUTTON_CLOSE = 'launcher_button_close'
export const BOT_MESSAGE = 'bot_message'
export const BOT_MAIN = 'bot_main'
export const BOT_BUTTON = 'bot_button'
export const EMAIL_CAPTURED = 'email_captured'
export const PHONE_CAPTURED = 'phone_captured'
export const BOT_STARTED = 'bot started'
export const CONTAINER_LESS_THEME = 'container-less'
export const CONTAINED_THEME = 'contained'
