/* eslint-disable no-tabs */
export const googleAdsInitialize = (adKey) => {
  if (document.getElementById('ga-ads-func') && document.getElementById('ga-gtag')) { return }
  if (window.gtag) {
    const analyticalfunc = document.createElement('script')
    analyticalfunc.id = 'ga-ads-func'
    analyticalfunc.innerHTML = `
  	gtag('config', '${adKey}', {
  		'send_page_view': false,
  	});`
    document.head.append(analyticalfunc)
    return
  }
  const analyticalAds = document.createElement('script')
  analyticalAds.id = 'ga-gtag'
  analyticalAds.async = true
  analyticalAds.src = `https://www.googletagmanager.com/gtag/js?id=${adKey}`
  document.head.append(analyticalAds)
  const analyticalfunc = document.createElement('script')
  analyticalfunc.id = 'ga-ads-func'
  analyticalfunc.innerHTML = `window.dataLayer = window.dataLayer || [];
	function gtag(){dataLayer.push(arguments);}
	gtag('js', new Date());
	gtag('config', '${adKey}', {
		'send_page_view': false,
	});`
  document.head.append(analyticalfunc)
}
