import { isEmpty } from './utils'

export const googleAnalyticsInitialize = (gaCode) => {
  if (document.getElementById('ga-analytical-launcher')) { return }
  const analyticalScript = document.createElement('script')
  analyticalScript.id = 'ga-analytical-launcher'
  analyticalScript.async = true
  analyticalScript.innerHTML = `
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
`
  document.head.append(analyticalScript)

  setTimeout(() => {
    window.ga(() => {
      if (isEmpty(window.ga.getByName('E0'))) {
        window.ga('create', gaCode, 'auto', 'E0')
      }
      if (isEmpty(window.ga.getByName('E1'))) {
        window.ga('create', gaCode, 'auto', 'E1')
      }
      if (isEmpty(window.ga.getByName('E2'))) {
        window.ga('create', gaCode, 'auto', 'E2')
      }
      if (isEmpty(window.ga.getByName('E3'))) {
        window.ga('create', gaCode, 'auto', 'E3')
      }
      if (isEmpty(window.ga.getByName('E4'))) {
        window.ga('create', gaCode, 'auto', 'E4')
      }
    })
  }, 0)
}
