import { getTime } from './utils'

export const getDayName = () => {
  var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  var d = new Date()
  var dayName = days[d.getDay()]
  return dayName
}

const getTimeOfDay = () => {
  const date = new Date()
  const hour = date.getHours()
  if (hour < 12) {
    return 'Good morning'
  }
  if (hour >= 12 && hour < 18) {
    return 'Good afternoon'
  }
  if (hour >= 18 && hour <= 23) {
    return 'Good evening'
  }
}

export const ProcessVariables = (variables = []) => {
  return variables.map(variable => {
    const { name } = variable
    if (name === 'DAY') {
      return { ...variable, default_value: getDayName() }
    }
    if (name === 'ENTRY_POINT') {
      return { ...variable, default_value: document.location.href }
    }
    if (name === 'TIME_OF_DAY') {
      return { ...variable, default_value: getTimeOfDay() }
    }
    if (name === 'TIME') {
      return { ...variable, default_value: getTime() }
    }
    return variable
  })
}
