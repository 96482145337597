export const icon4 = (color) => {
  return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="375pt" height="374.999991pt" viewBox="0 0 375 374.999991" version="1.2">
  <defs>
  <clipPath id="clip1">
    <path d="M 12 24.972656 L 358 24.972656 L 358 345.972656 L 12 345.972656 Z M 12 24.972656 "/>
  </clipPath>
  </defs>
  <g id="surface1">
  <rect x="0" y="0" width="375" height="374.999991" style="fill:transparent;fill-opacity:1;stroke:none;"/>
  <rect x="0" y="0" width="375" height="374.999991" style="fill:transparent;fill-opacity:1;stroke:none;"/>
  <g clip-path="url(#clip1)" clip-rule="nonzero">
  <path style=" stroke:none;fill-rule:nonzero;fill:${color};fill-opacity:1;" d="M 49.042969 24.839844 C 28.734375 24.839844 12.007812 41.578125 12.007812 61.902344 L 12.007812 185.4375 C 12.007812 205.761719 28.734375 222.5 49.042969 222.5 L 85.933594 222.5 L 85.933594 271.914062 L 151.816406 222.5 L 221.871094 222.5 C 242.179688 222.5 258.90625 205.761719 258.90625 185.4375 L 258.90625 61.902344 C 258.90625 41.578125 242.179688 24.839844 221.871094 24.839844 Z M 49.042969 49.546875 L 221.871094 49.546875 C 228.824219 49.546875 234.214844 54.941406 234.214844 61.902344 L 234.214844 185.4375 C 234.214844 192.398438 228.824219 197.792969 221.871094 197.792969 L 143.5625 197.792969 L 110.625 222.5 L 110.625 197.792969 L 49.042969 197.792969 C 42.089844 197.792969 36.699219 192.398438 36.699219 185.4375 L 36.699219 61.902344 C 36.699219 54.941406 42.089844 49.546875 49.042969 49.546875 Z M 283.597656 98.960938 L 283.597656 123.667969 L 320.628906 123.667969 C 327.585938 123.667969 332.976562 129.0625 332.976562 136.023438 L 332.976562 259.5625 C 332.976562 266.519531 327.585938 271.914062 320.628906 271.914062 L 259.050781 271.914062 L 259.050781 296.621094 L 226.109375 271.914062 L 147.800781 271.914062 C 142.84375 271.914062 138.648438 268.949219 136.671875 264.730469 L 116.753906 279.636719 C 123.359375 289.847656 134.800781 296.621094 147.800781 296.621094 L 217.859375 296.621094 L 283.738281 346.035156 L 283.738281 296.621094 L 320.628906 296.621094 C 340.9375 296.621094 357.664062 279.882812 357.664062 259.5625 L 357.664062 136.023438 C 357.664062 115.703125 340.9375 98.960938 320.628906 98.960938 Z M 283.597656 98.960938 "/>
  </g>
  </g>
  </svg>`
}
