export const closeIcon = (color = '#FFFFFF') => {
  return `<svg width="08px" height="08px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
  <polygon id="path-1" points="11.8333333 1.34166667 10.6583333 0.166666667 6 4.825 1.34166667 0.166666667 0.166666667 1.34166667 4.825 6 0.166666667 10.6583333 1.34166667 11.8333333 6 7.175 10.6583333 11.8333333 11.8333333 10.6583333 7.175 6">
  </polygon>
  </defs>
  <g id="Bot-designs" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g id="ic_close">
  <mask id="mask-2" fill="red">
  <use xlink:href="#path-1">
  </use>
  </mask>
  <use id="Mask" fill="${color}" xlink:href="#path-1">
  </use>
  </g>
  </g>
  </svg>
  `
}
