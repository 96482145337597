export const defaultIcon = (fill = '#000000') => {
  return `<svg width='26px' height='24px' viewBox='0 0 26 24' version='1.1' xmlns='http://www.w3.org/2000/svg'
  xmlnsXlink='http://www.w3.org/1999/xlink'>
  <g id='Bot-designs' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='style-2---1' transform='translate(-1349.000000, -689.000000)' fill='${fill}' stroke='${fill}'
      strokeWidth='1.81818182'>
      <g id='Group' transform='translate(1331.000000, 667.000000)'>
          <g id='Group-2' transform='translate(19.000000, 23.000000)'>
              <path
            d='M1.81818182,2 L18.1818182,2 C19.1859723,2 20,2.81402773 20,3.81818182 L20,13.4417526 C20,14.4459067 19.1859723,15.2599344 18.1818182,15.2599344 L16.4202696,15.2599344 L16.4202696,15.2599344 L15.5564457,20 L12.6571741,15.2599344 L1.81818182,15.2599344 C0.814027728,15.2599344 3.48707588e-16,14.4459067 0,13.4417526 L0,3.81818182 C9.90711956e-17,2.81402773 0.814027728,2 1.81818182,2 Z'
            id='Path' opacity='0.600000024' />
          <path
            d='M4.81818182,0 L22.1818182,0 C23.1859723,-1.84460114e-16 24,0.814027728 24,1.81818182 L24,11.4417526 C24,12.4459067 23.1859723,13.2599344 22.1818182,13.2599344 L20.2412831,13.2599344 L20.2412831,13.2599344 L19.334268,18 L16.2900328,13.2599344 L4.81818182,13.2599344 C3.81402773,13.2599344 3,12.4459067 3,11.4417526 L3,1.81818182 C3,0.814027728 3.81402773,-3.7584491e-17 4.81818182,0 Z'
            id='Path'
            transform='translate(13.500000, 9.000000) scale(-1, 1) translate(-13.500000, -9.000000) ' />
        </g>
    </g>
</g>
  </g>
</svg>`
}
