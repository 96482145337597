// This script is for landing page which is invoked from index.js
// its main script is written in landing.html where we are passing
// data-landing-page value as true, with this variable we determine
// it is a landing page call
import { LAUNCHER_URL } from './config/config'
export const LEFT = 1
export const CENTER = 2
export const RIGHT = 3
export const SOLID = 'Solid'
export const GRADIENT = 'Gradient'
export const TRANSPARENT = 'Transparent'
export const IMAGE = 'IMAGE'

export const isMobile = () => {
  return window.innerWidth <= 500
}

export const getBackgroundProperty = (element, bodyBackgroundColor, bodyBackgroundType, bodyGradientAngle, bodyGradientEnd, bodyGradientStart, bodyBgImageDesktop, bodyBgImageMobile) => {
  const styleElement = element.style
  if (bodyBackgroundType === SOLID) {
    styleElement.backgroundColor = bodyBackgroundColor
    return
  }
  if (bodyBackgroundType === GRADIENT) {
    styleElement.backgroundImage = `linear-gradient(${bodyGradientAngle}deg, ${bodyGradientEnd}, ${bodyGradientStart})`
    return
  }
  if (bodyBackgroundType === IMAGE) {
    styleElement.backgroundImage = `url(${bodyBgImageDesktop})`
    styleElement.height = '100%'
    styleElement.backgroundPosition = 'center'
    styleElement.backgroundRepeat = 'no-repeat'
    styleElement.backgroundSize = 'cover'
    if (isMobile()) {
      if (bodyBgImageMobile) {
        styleElement.backgroundImage = `url(${bodyBgImageMobile})`
        return
      }
      if (bodyBgImageDesktop) {
        styleElement.backgroundImage = `url(${bodyBgImageDesktop})`
        return
      }
    }
    if (bodyBgImageDesktop) {
      return
    }
    if (bodyBgImageMobile) {
      styleElement.backgroundImage = `url(${bodyBgImageMobile})`
    }
  }
}

export const getPosition = (pos) => {
  if (pos === LEFT) return 'flex-start'
  if (pos === CENTER) return 'center'
  if (pos === RIGHT) return 'flex-end'
}

export const getHeaderBackground = (element, backgroundType, solidColor, headerGradientAngle, headerGradientEnd, headerGradientStart) => {
  const styleElement = element.style
  if (backgroundType === SOLID) {
    styleElement.backgroundColor = solidColor
    return
  }
  if (backgroundType === GRADIENT) {
    styleElement.backgroundColor = solidColor
    styleElement.backgroundImage = `linear-gradient(${headerGradientAngle}deg, ${headerGradientEnd}, ${headerGradientStart})`
    return
  }
  if (backgroundType === TRANSPARENT) {
    styleElement.backgroundColor = 'transparent'
  }
}

export const getOverlayProperty = (element, bodyOverlayColor, overlayTransparency) => {
  element.style.backgroundColor = bodyOverlayColor
  element.style.opacity = parseFloat((10 - overlayTransparency) / 10).toFixed(2)
}

export const getLineSeparator = (element, lineSeparator, lineSeparatorColor) => {
  if (lineSeparator) {
    element.style.borderBottom = `1px solid ${lineSeparatorColor}`
  }
}

export const createElement = (element, classList, id) => {
  const el = document.createElement(element)
  if (id) el.id = id
  for (let i = 0; i < classList.length; i++) {
    el.classList.add(classList[i])
  }
  return el
}

export const LandingLauncher = (script) => {
  var data = JSON.parse(script.dataset.landingStyle.replace(/'/g, '"'))
  const botId = script.dataset.botId
  const { showHeader, bodyBackgroundColor, bodyBackgroundType, bodyGradientAngle, bodyGradientEnd, bodyGradientStart, bodyBgImageDesktop, bodyBgImageMobile, bodyOverlayColor, overlayTransparency, logoPosition, headerBackgroundType, solidColor, headerGradientAngle, headerGradientEnd, headerGradientStart, lineSeparator, lineSeparatorColor, showLogo, logoImageUrl, logoSize, logoHeight, logoText, logoTagline, logoTextSize, logoTextColor, fontFamily, logoTagSize, logoTagColor, showLogoText, enquiryBotBranding, customBranding, customWebsite, customBrand } = data

  const cssLink = createElement('link', [], 'landing-css')
  cssLink.rel = 'stylesheet'
  cssLink.type = 'text/css'
  cssLink.href = LAUNCHER_URL + 'landing.css'
  document.head.appendChild(cssLink)

  const section = createElement('section', ['landing-page-wrap', 'landing-page-wrap-preview'])
  const wrapBody = createElement('div', ['landing-page-wrap_body', 'landing-page-wrap_body_preview'])
  const previewPublic = createElement('div', ['landing', 'landing-preview', 'landing-preview-public'])
  // background style
  getBackgroundProperty(previewPublic, bodyBackgroundColor, bodyBackgroundType, bodyGradientAngle, bodyGradientEnd, bodyGradientStart, bodyBgImageDesktop, bodyBgImageMobile)

  // background overlay
  if (bodyBackgroundType === IMAGE) {
    const backgroundOverlay = createElement('div', ['background-overlay'])
    getOverlayProperty(backgroundOverlay, bodyOverlayColor, overlayTransparency)
    previewPublic.append(backgroundOverlay)
  }

  // landing header
  const landingHeader = createElement('div', ['landing-header'])
  landingHeader.style.justifyContent = getPosition(logoPosition)
  getHeaderBackground(landingHeader, headerBackgroundType, solidColor, headerGradientAngle, headerGradientEnd, headerGradientStart)
  getLineSeparator(landingHeader, lineSeparator, lineSeparatorColor)

  if (showLogo) {
    const logo = createElement('div', ['logo'])
    const img = createElement('img', ['logo-img'])
    img.src = logoImageUrl
    img.style.height = `${logoHeight}px`
    img.style.width = `${logoSize}px`
    logo.append(img)
    landingHeader.append(logo)
  }

  if (showLogoText) {
    const logoTextContainer = createElement('div', ['logo-text-container'])
    logoTextContainer.style.alignItems = getPosition(logoPosition)
    // logo text
    const text = createElement('span', ['logo-text'])
    text.innerHTML = logoText
    text.style.fontSize = `${logoTextSize}px`
    text.style.color = logoTextColor
    text.style.fontFamily = fontFamily
    // logo tagline
    const tagline = createElement('span', ['logo-tagline'])
    tagline.innerHTML = logoTagline
    tagline.style.fontSize = `${logoTagSize}px`
    tagline.style.color = logoTagColor
    tagline.style.fontFamily = fontFamily
    logoTextContainer.append(text)
    if (logoTagline && logoTagSize) {
      logoTextContainer.appendChild(tagline)
    }
    landingHeader.appendChild(logoTextContainer)
  }
  if (showHeader) {
    previewPublic.appendChild(landingHeader)
  }

  // bot body
  const landingBody = createElement('div', ['landing-body'])
  const botIframe = createElement('iframe', [], 'bot-landing-iframe')
  botIframe.title = 'bot-landing'
  botIframe.src = `${LAUNCHER_URL}landing.html`
  botIframe.name = botId
  botIframe.style.width = '100%'
  botIframe.style.height = '100%'
  botIframe.frameBorder = 0
  botIframe.title = 'bot-landing-iframe'
  landingBody.append(botIframe)
  previewPublic.appendChild(landingBody)

  // landing footer
  const landingfooter = createElement('div', ['landing-footer'])
  const poweredBy = createElement('div', ['powered-by'])
  const by = createElement('span', ['by'])
  by.innerHTML = 'by'
  const link = createElement('a', ['brand'])
  if (enquiryBotBranding && !customBranding) {
    link.href = 'https://enquirybot.com/'
    link.innerHTML = 'EnquiryBot'
  }
  if (!enquiryBotBranding && customBranding) {
    link.href = `${customWebsite}`
    link.innerHTML = `${customBrand}`
  }
  link.target = 'blank'
  link.rel = 'noopener noreferrer'
  poweredBy.append(by)
  poweredBy.appendChild(link)
  landingfooter.appendChild(poweredBy)
  previewPublic.appendChild(landingfooter)

  wrapBody.appendChild(previewPublic)
  section.appendChild(wrapBody)
  setTimeout(() => {
    document.body.appendChild(section)
  }, 500)
}
