export const prodV1 = [
  '7abe3428-c84a-4f78-9c08-eeca9c2d5254',
  '7e6c86c4-b672-4038-a15f-109fa16f6a6e',
  '89efa4fc-4fe3-4a2c-9b26-fbe01be08a20',
  '68d4fc70-f1c6-4650-9d96-90c3ffbfab85',
  '2f9363ab-85fa-470a-be01-425adc581687',
  '13ba2b1f-e8a7-4033-8772-7d619917bece',
  '8e3fbbdb-905b-4512-9035-8a879ae56e84',
  'e254df39-86dd-4809-bd1a-cb1485d109d5',
  'ddfddec9-889d-4f4c-aad7-872ca0f197b1',
  '47955d51-0de7-4a40-890f-0d7ed180677b',
  '942f8d4b-12c4-4d09-b4a1-347d735e69f3'
]

export const prodV2 = [
  '56166b3d-c77b-4331-8d6d-948c63a26faa',
  '0c3f8e1c-2a1b-462b-a6f3-0105d4d369f5',
  '11066c93-3e32-4ae0-a991-451292bc8edc',
  'a243d8ad-44a1-4441-aecd-b9b2a08ff886',
  'de4aa6bc-34bb-4968-b2f4-d5b0b1b5beb0',
  'd55c030e-2e08-4b6c-a6b3-fab3a087e452',
  'dfaa0c7c-c38a-4171-a63d-fa60307fdd1b',
  'ddc817b7-8967-4618-92b6-98f3b2f82afa',
  '90445845-d45b-4ad5-82a9-5f8581324379',
  '6716147a-21e3-4528-bc11-a67d01eba706',
  '3f05bc1b-c0d9-447e-90d2-692e3bc46c7e',
  'c12c5cb4-2124-43eb-85e5-563b4b45e9a5',
  '1a06e840-0f04-4376-9f0c-380487629057',
  '26aef230-a951-4673-a6ba-f0f2ee27909d',
  '4181d30a-5c23-4ae4-8dab-1a07c705f2be',
  'eaf7cf63-6689-4728-bbe6-354a033bc283',
  '6d7a9508-1604-4495-be76-022650e580f9',
  'f159188b-9292-494b-bb27-85e595bbb203',
  'f6460e5b-a82e-4dd5-b42f-15d5e1dc94cd',
  'af194f2f-4d40-40f9-af96-508d576be452',
  '348a3ef1-c857-45ec-aaa3-618f449024a9',
  'ac8f64e7-e603-41c9-8033-0d53b7a41ec1',
  '82e51c18-9ed2-42cb-8f2a-e1c0f0c8f9b2',
  '21d74ad0-a154-41bd-aef5-77a2a3c840c6',
  '5e2da1e9-db39-465f-9407-f18470718e06',
  '68e45cb9-95d2-494b-ae51-6fc6c6017cd4',
  '57795a09-d9e2-4921-97d7-ee7a0e12e14a',
  '13727852-7442-44f7-a04f-5dde1d95a4f9',
  '45215487-7640-4c77-a123-79cf79dbb30d',
  '7bcb453e-347f-435a-ae0b-0ac2689e96d7',
  'b7fc004a-55c4-434d-adc0-c1c60e0c07d8',
  '5fedc729-d3c2-47e1-8543-e21395a215ff',
  '836bf09d-8fd2-43b9-93d1-16b97208e1fd',
  'd60d4025-c98e-4138-8ae1-bb737c72f3e5',
  '359d3ba1-84f2-4bf2-a00a-76aba9f68628',
  '21f4835a-fc4b-498f-90b1-7e0a8cd58845',
  '77260a2c-cff9-40de-953c-6126043fcff6',
  'd1937e7c-ddcd-4745-8ba6-c3fbf61c155e',
  '0d1a3dda-3e82-4c00-ae70-10dfae7d40b7',
  '209e6e00-8f1e-488d-9093-115f207701bf',
  'ccd93354-782d-47e4-b0fc-794afba7c4ce',
  'f835de1d-365d-47ea-b35e-afbac35dd76c',
  'd4396135-f736-4eab-b106-9b08d1a1fd60',
  'af3ee485-d067-48c6-a7ed-4225259fc77e',
  '589db653-8e21-4d22-b58a-3f9b9c1971a5',
  '4ad587c1-c31d-444b-872e-6c1cb1a2000d'
]
// this is for bills
export const prodV3 = [
  '0ed93a2e-28e0-4b2d-a9cf-c595afe0c55d',
  '982fd2d9-f28a-4bf2-a86a-c58f8d9906d6',
  'e40cba78-bb62-4058-972b-24e797f07016',
  '4e57ae91-84b5-4ffc-ad33-32cf206a0798'
]
// this is for bills
export const prodV5 = [
  '0ed93a2e-28e0-4b2d-a9cf-c595afe0c55d',
  '982fd2d9-f28a-4bf2-a86a-c58f8d9906d6',
  'e40cba78-bb62-4058-972b-24e797f07016',
  '4e57ae91-84b5-4ffc-ad33-32cf206a0798'
]
export const prodV6 = [
  '4afd2170-fb0b-4925-8d5b-1581a0dad3d3',
  'c2fac780-b577-47d8-b162-938edc6582f6',
  '8abb9e79-dd9c-4961-8949-3ec01bad7be3',
  '86099727-b5a0-4a1e-b526-e82f7e9d80d6',
  'ab501122-d39f-4bf2-bbfd-7fb869f9e4d5',
  '4377e70b-3ca0-4b37-992d-6ab639f38263',
  '84476659-fb11-4969-998d-77bcb9b03b94',
  'b2cd75b0-9a01-49f8-ba8e-c37ec8150929',
  '26810433-f5b5-44e2-8ee6-ecea59924a4a',
  '27e5a8bb-6c34-43f5-9951-b35460a4c953',
  'cb301792-89a9-47da-a876-00e86ad5c8cd',
  'd0ee16ee-0259-4b91-94a7-6529529261a2',
  '6aa0ae73-0907-46c0-8e57-f8f46ed75143',
  'b783e336-8642-40d9-9d50-28b03013df9b',
  '875178ca-acae-44f5-8fac-aab2de2a5a7d',
  '90d85909-c641-4147-9162-c7e1ffdafee5',
  '1e54ecc2-5990-4fd1-8308-a861d7b46fd6',
  '1f483101-ca5a-4d04-ae9e-817f7633f554',
  '34985668-e2a6-4282-a9df-44f33fc9b4fd',
  '526a08a6-e606-4646-855a-c34502515780',
  'f84ce5ca-5f51-48e7-b871-da0fa4053499',
  '0c375a0d-bec4-426d-ab48-5fd8c04559a2',
  '09fbf83c-50fc-4bcb-b479-3aa888789621',
  '3e58f177-4404-4316-b5c2-949103795398',
  '214bd5f8-872a-4f96-b02e-b3dfbada2a0d',
  'a2026e49-603f-4229-a2e6-798efd73caac',
  'c392a08e-5fc7-47f2-8de6-85b30cacfbac',
  'f2c5fb24-9ad6-49b1-bcc0-01c6482b0444',
  '8f50653b-2155-4f54-8e49-487fb232e501',
  'cef977b6-b129-4681-9b23-2ea8f9462f13',
  '7f81e8f0-b46d-498f-933a-608c992205e5',
  '61a786dd-9101-4c0d-b2fd-30c4f1b135b8',
  '8379f39e-adfa-41e5-b32d-b84913468110',
  '02e66f34-9a2c-46a5-b177-0d816054ee9b',
  'c171cc4e-5bee-4f67-b2eb-322b0500b9d5',
  '7692acc9-ff71-493a-99ba-93c6453b7231',
  'ed554537-712b-4e27-b8d0-26dc3fe8685a',
  'b47cc83b-7e07-4511-9bfe-916a04131d3c',
  '904b3a1e-b425-440e-bc11-8a037eafa81e',
  'adeeda8b-0780-43a3-b046-6dbf0cbbd1ba',
  'c9e9e513-e089-4304-8448-8b55b10a350a',
  '1803a4de-e710-4a2b-8453-271d6c8dfdb0',
  '953e593a-5281-4829-b564-ce53b4df3aa5',
  'f5950cc3-2c57-4685-8cf0-7450f6989c9e',
  '165d4bb2-093d-42a5-a6f6-cf1a1ece756c',
  'a6f6d8f8-9634-4b52-ac7a-080e8df8ab9d',
  'e3d48165-1818-4d48-9b93-6f0b2faec107',
  'a0adc141-b6b7-4db0-8c18-27d9ef2e59e2',
  '426961bd-f42e-484c-9576-1f06145e7152',
  'b3e1517d-fc95-4095-8ff8-b0b56942b765',
  '764c6301-3016-4dec-880f-379861309e42',
  'cbe8236f-d01c-4b8f-9c48-a1a6f16026f3',
  '62111a49-2d31-479d-8215-0fa72c4c7650',
  '6ad3bc14-e6eb-4fd2-817d-b9352da028b5',
  '10bb912b-14be-4c7b-b9a0-876dfd84ba4b',
  'e3474001-3445-46e3-a581-c64b4bb11f15',
  'd99f1d9a-ca85-44d6-99d8-2075fad16ef1',
  '3dc223d8-ebcb-4250-9b0f-e81025456ff4',
  '30a942c1-2853-495e-83c0-c31ad19f4c58',
  'b266929f-41c3-4502-b987-0c54842d28b3',
  '6cd237f7-6cae-44ac-b393-4b9c2cd21bfd',
  'd6f4be6a-ec5f-4c70-b2b2-141b9cd70529',
  'ae041b07-02ef-4082-8603-d4edb81ef01f',
  '78cd9e8a-9c07-4b3a-8268-b5da1e683d94',
  'e6e9c6af-d56a-4902-9f04-e477607a45ec',
  '3667d391-ba78-4327-87af-6ec9822df693',
  '7b316084-5328-4dfe-b10b-d4f5b5d0a8cb',
  '2331f1e9-a903-4cc7-87a3-795ee5f58257',
  '6d3ee4f8-e424-4e7b-868b-23ebc4c3155e',
  '1f4d53d4-a81e-43be-bc7a-49231f00a824',
  'b5804353-5d6d-4de2-aeba-d66c31827288',
  '4a11e6db-bdb9-4794-95ab-fd108e106644',
  'd088f425-499e-43bc-a505-8a48771fd257',
  '4db00eef-0de5-4c2d-88ec-e0363daa97c0',
  'ec95b4b2-f9ce-44fc-b051-39c7dabd07f4',
  '74934252-7dd6-4945-8306-21971083ba26',
  '4437210f-d038-46fd-a908-4222722b6697',
  'ef925d99-e282-41b1-8688-15c3448cffd0',
  'd70ba6b4-e25d-403b-9ecc-e9a2169e73b0',
  '978afba6-c998-45df-9315-477f24a30374',
  'b8c7b471-83cb-48a5-a0d9-beccd69c23fe',
  '05cff300-13ce-485d-9aa0-5376c2201bfa',
  'db8ce25c-958a-42d7-b2f9-18782b1e537f',
  '4f40b464-54e5-4ea3-91cc-7897b22dc8b2',
  '9aa1259f-acee-4c01-a194-5c54892e2892',
  '8576eee8-77ae-48bd-b27e-55c79473f7b3',
  '746ff8b1-a5dc-40b2-bdef-950d34b8c4a3',
  '12932f45-5f98-473a-b789-faed372ca6b2',
  '5b356301-37ef-42ae-bfef-cf00b4990681',
  'a191d4d1-6a2c-40f5-98b0-e94aebc2e262',
  'd3f80bb0-f3c9-4878-8b51-e7422710c29c',
  '6dc3a5c9-ff14-4b9b-ac73-9eedf7511ac4',
  '4dbafe68-5955-4b8e-9359-1bc31b9ce62b',
  'dd10f5e4-97b4-4f2c-95e9-5fe4281cf3c2',
  '71aa3ae6-5309-4ad7-b09e-428cd8abfbd2',
  '8b76a655-c417-4fc1-a4f7-8a30ad9d5ac6',
  'ac26fcb1-405b-4b34-8a57-c2c29a63215b',
  'd4e631eb-bc7a-4913-a70f-b05bd93c48cb',
  '5bcc7d70-4707-48bc-9ee9-ea69aab1bf4a',
  'f4a44ef8-77d8-414a-ad19-fd7da195b7d9',
  '547fef25-2444-40e0-bcbd-810c287fc857',
  '69df7682-c116-4ed5-8e98-1fb7baba1a38',
  '11591a3c-0ef7-4216-a405-bd60b4e395a0',
  '5e590a88-9de1-4a1c-ae66-6fea3424d4c8',
  'daa933f1-e9cb-4e58-b577-33f803958548',
  'be061364-eb90-44e8-adfb-275fe95f22ce',
  'a8d4b96f-ce43-460c-a2e1-0c9dfc48063e',
  '47620438-401d-4bc9-99dd-cca52a8e45f8',
  '6f78454f-2f12-47e3-bf09-7ca54e664c8a',
  '92558bd1-fa10-48db-9266-cc36004b9487',
  '58bb6487-105b-433d-919a-222ed189fb32',
  'b25c731a-9308-43b4-ae89-3aee8a8dab7f',
  '1855d398-0039-4628-9695-44ea28da04f0',
  '81332fd6-de05-42fd-85c7-ac63c0879599',
  '71d22109-6de7-4a37-bd8c-4eb987a22f9b',
  '0a6f7e66-1d3a-4854-8411-10fa61eccf91',
  '522c00f2-270a-4bba-a305-32ba35d4161d',
  'c16ffa3c-c7c9-4267-847d-9bd3b31e39ed',
  'a653b0d0-d87f-4ca1-b482-6fa13d260ad9',
  'a59b0f03-57a9-429f-9606-a05b11a5a613',
  '91499a94-43e7-4591-9a97-d8c4af2225fd',
  '6502d0c1-d2a1-4a53-8dd8-f77a82852030',
  'c9965d3f-edcb-439f-be17-a02df72f49a7',
  'a9915697-9bb1-4815-9e09-1297c6284dc8',
  'e4de2f5e-7e98-4071-ab62-24f1cb860781',
  '63e3783f-3507-4bca-9236-7b746c674233',
  '0ad3f807-6ffc-499f-84f4-34243e2de5e6',
  '6d94ff58-4243-4b1f-b462-9eadfbc107a3',
  '934db45e-8963-421e-adc4-92fd31117077',
  '2b89fc4f-316a-415b-97cb-9da9f37cf691',
  '319e7c2a-1ee2-489f-baa6-9bb7dd6775e0',
  'f962878b-543a-4aa1-a295-e899748bba39',
  'e1b47507-d408-43e9-830a-97c7078c2cde',
  '5d7c36af-986b-4352-b196-80a836cf7d76',
  '44582654-f560-4dc4-ae19-b86c31518473',
  'fd206ce6-c331-4d60-ae3d-eee76902b186',
  '2c05d629-64de-4815-aa04-dcdd5feee2db'
]
