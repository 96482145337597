/* eslint-disable eqeqeq */
// This is a function for EB Trigger Button
import {
  createElement,
  addClass,
  setAttribute
} from './dom'
import { hexToRgbA, detectMob, svgToBase64Url, isMobile } from './utils'
import { getIsOnline } from './isOnline'
import { getIcon } from './../icons/getIcon'
import { closeIcon } from './../icons/close'

export const showBotTrigger = (data) => {
  if (data) {
    if (data.is_deactivated) {
      if (window.location.href.includes('preview')) {
        return true
      } else {
        return false
      }
    } else {
      const isMobile = detectMob()
      if (isMobile) {
        if (data.theme.show_bot_on_mobile) {
          return true
        } else {
          return false
        }
      } else {
        if (data.theme.show_bot_on_desktop) {
          return true
        } else {
          return false
        }
      }
    }
  } else {
    return false
  }
}

// show pop up for widget
export const shouldPop = (theme, launchButton) => {
  const isMobile = detectMob()
  if (isMobile) {
    if (theme.pop_in_phone) {
      if (!theme.is_single_btn) {
        if (!launchButton.classList.contains('bot-popup')) {
          addClass(launchButton, 'bot-popup')
        }
      }
    }
  }
  if (!isMobile) {
    if (theme.pop_in_desktop) {
      if (!theme.is_single_btn) {
        if (!launchButton.classList.contains('bot-popup')) {
          addClass(launchButton, 'bot-popup')
        }
      }
    }
  }
}

export const botLaunchButton = (element, theme = {}) => {
  const count = createElement('span')
  const eTextContainer = createElement('div')
  const eText = createElement('span')
  const roundContainer = createElement('span')
  const logo = createElement('img')
  const tooltip = createElement('div')
  addClass(element, 'bot-eb-trigger')
  setAttribute(element, 'id', 'eb-bot-trigger')
  setAttribute(logo, 'id', 'bot-logo')
  setAttribute(logo, 'alt', 'bot-logo')
  addClass(logo, 'bot-open')
  addClass(tooltip, 'bot-eb-tooltip')
  addClass(tooltip, 'fade')
  setAttribute(tooltip, 'id', 'bot-tooltip')
  setAttribute(tooltip, 'data-title', 'You\'re offline')
  getIsOnline()
    .then((isOnline) => {
      if (isOnline) {
        tooltip.style.opacity = 0
      }
    })
  if (theme.placement == 1 && !isMobile()) {
    element.style.left = `${theme.side_spacing}px`
    element.style.bottom = `${theme.bottom_spacing}px`
  }
  if (theme.placement == 1 && isMobile()) {
    element.style.left = '25px'
    element.style.bottom = `${theme.bottom_spacing_for_mobile}px`
  }
  if (theme.placement == 2 && isMobile()) {
    element.style.right = '25px'
    element.style.bottom = `${theme.bottom_spacing_for_mobile}px`
  }
  if (theme.placement == 2 && !isMobile()) {
    element.style.right = `${theme.side_spacing}px`
    element.style.bottom = `${theme.bottom_spacing}px`
  }
  if (theme.is_cta_bg_gradient) {
    const left = theme.cta_bg_gradient_left
    const right = theme.cta_bg_gradient_right
    element.style.backgroundImage = `linear-gradient(to right, ${left}, ${right})`
  }
  if (theme.is_chat_icon_bg_gradient) {
    const iconLeft = theme.chat_icon_bg_gradient_left
    const iconRight = theme.chat_icon_bg_gradient_right
    roundContainer.style.backgroundImage = `linear-gradient(to right, ${iconLeft}, ${iconRight})`
  }
  if (theme.is_chat_icon_bg_solid) {
    roundContainer.style.background = hexToRgbA(theme.chat_icon_bg_solid_colour)
  }
  if (theme.icon_type === 0) {
    setAttribute(logo, 'src', theme.selected_avatar)
    addClass(logo, 'selected-avatar')
  } else {
    const svg = getIcon(theme.icon_colour, theme.icon_type)
    setAttribute(logo, 'src', svgToBase64Url(svg))
  }

  const close = createElement('img')
  setAttribute(close, 'id', 'bot-close')
  setAttribute(close, 'alt', 'bot-close')
  addClass(close, 'bot-cover')
  addClass(close, 'bot-close')

  const closeSvg = closeIcon(theme.icon_colour || '#00000')
  setAttribute(close, 'src', svgToBase64Url(closeSvg))
  addClass(count, 'count')
  count.innerHTML = '1'

  addClass(eTextContainer, 'bot-e-text')
  eText.innerHTML = theme.trigger_text || 'Make An Enquiry'
  addClass(eText, 'bot-text')
  eTextContainer.appendChild(eText)

  addClass(roundContainer, 'round-container')
  roundContainer.appendChild(logo)
  roundContainer.appendChild(close)
  element.appendChild(tooltip)
  element.appendChild(count)
  element.appendChild(eTextContainer)
  element.appendChild(roundContainer)
}
