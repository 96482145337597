// eslint-disable-next-line space-before-function-paren
export const isGtag = () => {
  return window.gtagalias || null
}

export const ga4Send = (data) => {
  window.gtag('event', 'pageview')
}

export const sendGa4Started = (ga4Code) => {
  window.gtag('event', 'Enquiry Started', { send_to: ga4Code })
}

export const sendGa4Email = (ga4Code) => {
  window.gtag('event', 'Email Captured', { send_to: ga4Code })
}

export const sendGa4Phone = (ga4Code) => {
  window.gtag('event', 'Phone Captured', { send_to: ga4Code })
}

export const sendGa4Dismiss = (ga4Code) => {
  window.gtag('event', 'Enquiry Dismissed', { send_to: ga4Code })
}

export const sendGa4Completed = (ga4Code) => {
  window.gtag('event', 'Enquiry Completed', { send_to: ga4Code })
}

export const gA4ds = () => {
  return window.gtag || null
}
