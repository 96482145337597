export const LAUNCHER_URL = 'https://launcher.enquirybot.com/'
export const WIDGET_URL = 'https://widget.enquirybot.com/#/'
export const SOCKET_URL_V1 = 'prod-big.enquirybot.com'
export const SOCKET_URL_V2 = 'prod-qa.enquirybot.com'
export const SOCKET_URL_V3 = 'cta.enquirybot.com'
export const SOCKET_URL_V5 = 'cta.enquirybot.com'
export const SOCKET_URL_V6 = 'server2.enquirybot.com'
export const SOCKET_URL = 'https://fourthsocket.enquirybot.com/'
export const API_BASE_URL = 'https://load.enquirybot.com'
export const API_BASE_URL_BETA = 'https://billsload.enquirybot.com'
export const POWEREDBY_URL = 'https://enquirybot.com/whoops'
export const BOT_IMAGE_URL = 'https://screenshots-demo-eb.s3-eu-west-1.amazonaws.com/botimage.svg'
export const MATERIAL_ICON_URL = 'https://fonts.googleapis.com/icon?family=Material+Icons'
